.content {
  display: flex;
  flex-direction: column;
  width: 66%;
}

.inactive {
  text-decoration: none;
  color: var(--primary);
}

.transfer {
  font-size: 18px;
}

.debounce {
  max-width: 240px;
  min-width: 160px;
}

.header {
  flex: 0 1 auto;
  margin-bottom: 20px;
}

.header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  composes: mb-4 from global;
}

.header-text {
  font-size: 48px;
}

.historical {
  font-size: 20px;
}

.transfer-link {
  composes: btn from global;
  composes: btn-primary from global;
  display: flex !important;
  flex-wrap: nowrap !important;
  align-items: center !important;
}

.transfer-link span {
  margin-left: 5px;
}

.list {
  flex-grow: 1;
  border: 1px solid var(--white-two);
  border-radius: 5px;
  background-color: white;
  padding: 5px;
}

@media only screen and (max-width: 1340px) {
  .content {
    width: 100%;
  }
}
