.app-container {
  display: flex;
  flex-flow: column;
  height: 100%;
  background-color: var(--white);
}
.nav-container {
  flex: 0 1 auto;
}
.content-container {
  display: flex;
  flex: 1 1 auto;
}
.footer-container {
  flex: 0 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.brand-image {
  width: 144px;
  height: 50px;
  margin-left: 1em;
  display: inline-block;
  background-size: contain;
  background-image: url('../../static_assets/images/inflowmatix_logo.png');
  background-repeat: no-repeat;
  background-position: center;
}
