.content {
  composes: content from '../transfer-devices-between-tenants-table/styles.module.css';
  width: 90% !important;
  display: flex;
  justify-content: space-between !important;
  gap: 1rem;
}

.header {
  composes: header from '../transfer-devices-between-tenants-table/styles.module.css';
}

.body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 4rem;
}
