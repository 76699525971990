.card-container {
  composes: card-container from '../log-in/styles.module.scss';
}

.error-message-tip {
  white-space: nowrap;
  color: red;
  font-size: 12px;
}

.list-elements {
  list-style-type: none;
  li {
    font-size: 14px;

    &::before {
      content: '•';
      margin-right: 5px;
      font-size: 14px;
    }
  }
}
