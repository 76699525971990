@import '../../_theme/colors.css';

.list-item-text {
  flex-grow: 1 !important;
  flex-shrink: 1;
}

.badges-container {
  display: flex;
}

.list-item-button {
  flex-grow: 0 !important;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.view-logs-button {
  font-weight: bold !important;
  min-width: max-content !important;
  background-color: var(--white-two) !important;
  border: 0px !important;
  color: black !important;
}
