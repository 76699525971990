:root {
  --blue: #5b9bd5;
  --charcoal: #303845;
  --sunflower: #ffc900;
  --white-two: #dddddd;
  --crimson: #dc3545;
  --white: #f7f7f7;
  --pure-white: #ffffff;
  --green: #64b900;
  --steel: #7f899a;
  --tangerine: #f94f29;

  --primary: #46baba;
  --primary-darker: #3b9f9f;
  --primary-lighter: #eff6f7;

  --master: #ced4da;
  --master-lighter: #e9ecef;
  --master-darkest: #212529;

  /* alerts */
  --alert-primary: #c6f2f2;
  --alert-secondary: #dee3eb;
  --alert-success: #deebcf;
  --alert-danger: #f8d7da;
  --alert-warning: #fff0b7;
  --alert-info: #d0e1f0;
  --alert-light: #fefefe;
  --alert-dark: #cfd2da;

  --alert-primary-text: #138585;
  --alert-secondary-text: #424953;
  --alert-success-text: #3b6905;
  --alert-danger-text: #6f1b23;
  --alert-warning-text: #7b6101;
  --alert-info-text: #2e5070;
  --alert-light-text: #818283;
  --alert-dark-text: #081024;
}
