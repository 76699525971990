.app-container {
  display: flex;
  flex-flow: column;
  height: 100%;
  background-color: var(--white);
  background-size: cover;
  background-image: url('../../static_assets/images/inflowmatix_guest_background.png');
  background-repeat: no-repeat;
  background-position: center;
}

.nav-container {
  flex: 0 1 auto;
}

.content-container {
  display: flex;
  flex: 1 1 auto;

  .centered-container {
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
  }
}

.footer-container {
  height: 50px;
  flex: 0 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  color: white;
}

.version-number {
  color: var(--white);
  opacity: 0.8;
}

.brand-image {
  width: 144px;
  height: 50px;
  margin-left: 1em;
  display: inline-block;
  background-size: contain;
  background-image: url('../../static_assets/images/inflowmatix_logo.png');
  background-repeat: no-repeat;
  background-position: center;
}
