.fullscreen {
  height: 100vh;
  background-color: var(--white);
  overflow: auto;
}

.no-content-spinner {
  position: absolute;
  width: 4rem !important;
  height: 4rem !important;
  top: calc(50% - 2rem);
  left: calc(50% - 2rem);
}
