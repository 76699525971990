.custom-pagination {
  ul {
    margin: 0;
  }
}

.per-page-input {
  margin-bottom: 0 !important;
  composes: form-control from global;
}

.results-per-page-label {
  composes: pr-2 from global;
  composes: mb-0 from global;
  composes: text-nowrap from global;
  composes: font-weight-normal from global;

  opacity: 0.5;
}

.per-page {
  margin: 0px !important;
}
