@import '../../_theme/colors.css';

.steps-container {
  flex-basis: 67% !important;
  flex-grow: 0 !important;
}

.step_one-tenants-containers {
  display: flex;
  position: relative;
  margin-bottom: 3rem !important;

  .step_one-tenants-container {
    flex: 1;
    min-width: 180px;
  }

  i {
    margin-bottom: 11px;
  }
}

.status-badge {
  padding: 10px 15px;
  max-width: fit-content;
  font-size: 13px;
}

.status-badge-pending {
  background-color: var(--alert-info) !important;
  color: var(--alert-info-text) !important;
}

.status-badge-finished {
  background-color: var(--alert-dark) !important;
  color: var(--alert-dark-text) !important;
}

.status-badge-failed {
  background-color: var(--alert-danger) !important;
  color: var(--alert-danger-text) !important;
}

.step_one-serial-numbers-field {
  position: relative;
  width: 50%;
  height: 200px;
  max-height: 200px;
  margin-right: 5% !important;
}

.untouched-height {
  height: 100px !important;
}

.all-devices-button {
  background-color: white !important;
  border-color: var(--primary) !important;
  color: var(--primary) !important;
}

.text-decoration-underline {
  cursor: pointer;
  text-decoration: underline;
}

.device-serial-number-text-area {
  composes: form-control from global;
  width: 100%;
  height: max-content;
  min-height: 100px;
  max-height: 200px;
}

.code-card {
  composes: form-control from global;
  height: unset !important;
  font-family: monospace;
}

.devider {
  width: 100%;
  height: 1px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  background-color: var(--master-lighter);
}

.step_one-serial-numbers-info {
  width: 45%;
  color: var(--steel);
  .info-icon {
    flex-grow: 0 !important;
    margin-right: 0.5rem;
    font-size: 15.5px;
  }
}

.transfer-control-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem !important;
  padding-top: 1rem !important;
  border-top: 1px solid var(--master);
}

.error-message-tip {
  position: absolute;
  white-space: nowrap;
  color: red;
  bottom: -22px;
}
