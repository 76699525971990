@import '../../_theme/colors.css';

.main-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--white);
  padding: 10px !important;
}

.bg-done {
  background-color: var(--primary-lighter);
  color: var(--primary);
}

.circle-defaults {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 4px solid var(--primary);
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.progress-circle-off {
  background-color: var(--steel);
  outline-color: var(--white);
}

.progress-circle-ongoing {
  background-color: var(--primary);
  outline-color: #c3e6e7;
  transition: outline-width ease-in-out;
  animation: pulse 1s ease-in-out infinite alternate;
}

.progress-circle-done {
  background-color: var(--primary);
}

.check {
  color: white;
  padding-top: 1px;
  font-size: 4px;
  transform: scale(2.3);
}

.text {
  margin-left: 1rem;
}

@keyframes pulse {
  0% {
    outline-width: 2px;
  }

  100% {
    outline-width: 5px;
  }
}
