.card-container {
  composes: card-container from '../log-in/styles.module.scss';
  max-width: 300px;
}

.error-message-tip {
  composes: error-message-tip from '../log-in/styles.module.scss';
}

.text {
  font-weight: 500;
}
