.card-container {
  display: flex;
  composes: px-3 from global;
  composes: py-4 from global;
  gap: 1rem;
  min-width: 300px !important;
  max-width: 400px !important;
}

.error-message-tip {
  white-space: nowrap;
  color: red;
  font-size: 12px;
}
