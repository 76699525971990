.card-container {
  composes: card-container from '../log-in/styles.module.scss';
  width: 120px;
}

.error-message-tip {
  composes: error-message-tip from '../log-in/styles.module.scss';
}

.info-text-container {
}

.info-text {
}
