html,
body,
:global(#container) {
  height: 100%;
  margin: 0;
}

/* extension for inflowmatix bootstrap theme */
:global(.border-transparent) {
  border-color: transparent;
}

.visually-hidden {
  display: none !important;
}

.col,
.row {
  padding: 0;
  margin: 0;
}

textarea.textarea-h50 {
  height: 50%;
}
